import axios from 'axios';
import {
    SSO_URL, SSO_API_KEY
} from "../../config";

const GET_WIDGET_ACTIONS_DATA_SUCCESS = "GET_WIDGET_ACTIONS_DATA_SUCCESS";
const GET_WIDGET_ACTIONS_DATA_FAILED = "GET_WIDGET_ACTIONS_DATA_FAILED";
const GET_WIDGET_ACTION_COUNT_SUCCESS = "GET_WIDGET_ACTION_COUNT_SUCCESS";
const GET_WIDGET_ACTION_COUNT_FAILED = "GET_WIDGET_ACTION_COUNT_FAILED";
const REMOVE_WIDGET_ACTION_SUCCESS = "REMOVE_WIDGET_ACTION_SUCCESS";
const REMOVE_WIDGET_ACTION_FAILED = "REMOVE_WIDGET_ACTION_FAILED";
const ENABLE_WIDGET_ACTION_SUCCESS = "ENABLE_WIDGET_ACTION_SUCCESS";
const ENABLE_WIDGET_ACTION_FAILED = "ENABLE_WIDGET_ACTION_FAILED";
const CREATE_WIDGET_ACTION_SUCCESS = "CREATE_WIDGET_ACTION_SUCCESS";
const CREATE_WIDGET_ACTION_FAILED = "CREATE_WIDGET_ACTION_FAILED";
const UPDATE_WIDGET_ACTION_SUCCESS = "UPDATE_WIDGET_ACTION_SUCCESS";
const UPDATE_WIDGET_ACTION_FAILED = "UPDATE_WIDGET_ACTION_FAILED";
const WIDGET_ACTION_SEARCH_SUCCESS = "WIDGET_ACTION_SEARCH_SUCCESS";
const WIDGET_ACTION_SEARCH_FAILED = "WIDGET_ACTION_SEARCH_FAILED";

const INIT_STATE = {
    loading: false,
    errorMessage: null
};

export default function reducer(state = INIT_STATE,action) {
    const {type, payload} = action;

    switch (type){
        case GET_WIDGET_ACTIONS_DATA_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_WIDGET_ACTIONS_DATA_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_WIDGET_ACTION_COUNT_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_WIDGET_ACTION_COUNT_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case REMOVE_WIDGET_ACTION_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case REMOVE_WIDGET_ACTION_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case ENABLE_WIDGET_ACTION_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case ENABLE_WIDGET_ACTION_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case CREATE_WIDGET_ACTION_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case CREATE_WIDGET_ACTION_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case UPDATE_WIDGET_ACTION_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case UPDATE_WIDGET_ACTION_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case WIDGET_ACTION_SEARCH_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case WIDGET_ACTION_SEARCH_FAILED:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }
}

export const widgetActionsData = (limit,offset,widgetId) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget_action/all`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data: {
                "limit": limit,
                "offset": offset,
                "widgetId":widgetId
            }
        });


        if(res && res['data'].message==='WIDGET_ACTIONS_FOUND'){
            const payload = [];
            res['data'].data.map((item)=>{
                payload.push(
                    {
                        id:item['id'],
                        name:item['name'],
                        widget_name:item['widget'].name,
                        short_code:item['short_code'],
                        url:item['url'],
                        widget_short_code:item['widget'].short_code,
                        icon:item['icon'],
                        action_type:item['action_type'],
                        display_order:item['display_order'],
                        status:item['status'],
                    }
                );
            })
            return dispatch({type: GET_WIDGET_ACTIONS_DATA_SUCCESS, payload: payload});
        }else{
            return dispatch({type: GET_WIDGET_ACTIONS_DATA_FAILED, payload: {error:res['data'].message}});
        }

    }catch (e){

        return dispatch({type: GET_WIDGET_ACTIONS_DATA_FAILED, payload: false});
    }
}

export const widgetActionsCount  = (widgetId) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget_action/count`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                widgetId:widgetId
            }
        });
        if(res && res['data'].message==='WIDGETS_ACTION_COUNT' && res['data'].status==='OK'){
            return dispatch({type: GET_WIDGET_ACTION_COUNT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_WIDGET_ACTION_COUNT_FAILED, payload: 0});
        }
    }catch (e){
        return dispatch({type: GET_WIDGET_ACTION_COUNT_FAILED, payload: 0});
    }
}

export const removeWidgetAction = (short_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/widget_action/remove/`+short_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='WIDGET_ACTIONS_REMOVED' && res['data'].status==='OK'){
            return dispatch({type: REMOVE_WIDGET_ACTION_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: REMOVE_WIDGET_ACTION_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: REMOVE_WIDGET_ACTION_FAILED, payload: false});
    }
}

export const enableWidgetAction = (short_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/widget_action/enable/`+short_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='WIDGET_ACTION_ENABLED' && res['data'].status==='OK'){
            return dispatch({type: ENABLE_WIDGET_ACTION_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: ENABLE_WIDGET_ACTION_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: ENABLE_WIDGET_ACTION_FAILED, payload: false});
    }
}

export const createWidgetAction = (data) => async dispatch => {

    let url,icon;

    if(data['url']){
        url = data['url'];
    }else{
        url = "";
    }

    if(data['icon']){
        icon = data['icon'];
    }else{
        icon = "";
    }

    try{

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget_action/create`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "dataType":"json"
            },
            data: {
                widget_code: data['widget_code'],
                name: data['name'],
                code: data['code'],
                url: url.toString(),
                display_order: data['display_order'],
                icon: icon.toString(),
                action_type: data['action_type']
            }
        });
        if(res && res['data'].message==='WIDGET_ACTION_CREATED' && res['data'].status==='OK'){
            return dispatch({type: CREATE_WIDGET_ACTION_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: CREATE_WIDGET_ACTION_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: CREATE_WIDGET_ACTION_FAILED, payload: e});
    }
}

export const searchWidgetAction = (keyword) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget_action/search`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                key_word:keyword
            }
        });
        if(res && res['data'].message==='WIDGET_FOUND' && res['data'].status==='OK'){
            const payload = [];
            res['data'].data.map((item)=> {
                payload.push(
                    {
                        id:item['id'],
                        name:item['name'],
                        widget_name:item['widget'].name,
                        short_code:item['short_code'],
                        url:item['url'],
                        widget_short_code:item['widget'].short_code,
                        icon:item['icon'],
                        action_type:item['action_type'],
                        display_order:item['display_order'],
                        status:item['status'],
                    }
                );
            });
            return dispatch({type: WIDGET_ACTION_SEARCH_SUCCESS, payload: payload});
        }else{
            return dispatch({type: WIDGET_ACTION_SEARCH_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: WIDGET_ACTION_SEARCH_FAILED, payload: false});
    }
}

export const updateWidgetAction = (data) => async dispatch => {

    let url,icon;

    if(data['url'] && data['url']!==null){
        url = data['url'];
    }else{
        url = "";
    }

    if(data['icon'] && data['icon']!==null){
        icon = data['icon'];
    }else{
        icon = "";
    }

    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget_action/update`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "dataType":"json"
            },
            data: {
                widget_code: data['code'],
                name: data['name'],
                code: data['widget_code'],
                url: url,
                display_order: data['display_order'].toString(),
                icon: icon,
                action_type: data['action_type']
            }
        });
        if(res && res['data'].message==='WIDGET_ACTION_UPDATED' && res['data'].status==='OK'){
            return dispatch({type: UPDATE_WIDGET_ACTION_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: UPDATE_WIDGET_ACTION_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: UPDATE_WIDGET_ACTION_FAILED, payload: e});
    }
}