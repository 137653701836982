

const REQUEST_QUOTATIONS = "REQUEST_QUOTATIONS";

export default function (state=null,action){
    switch (action.type){
        case REQUEST_QUOTATIONS:
            return action.payload;
            break;
    }
    return state;
}