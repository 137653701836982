export const AUTH_API = process.env.REACT_APP_AUTH_URL;

export const AUTH_USER_NAME = process.env.REACT_APP_AUTH_USER_NAME;

export const AUTH_PWD = process.env.REACT_APP_AUTH_PASSWORD;

export const API_KEY = process.env.REACT_APP_AUTH_API_KEY;

export const SSO_URL = process.env.REACT_APP_SSO_URL;

export const SSO_API_KEY = process.env.REACT_APP_SSO_API_KEY;

export const APP_TYPE = process.env.REACT_APP_TYPE;

export const APP_NAME = process.env.REACT_APP_NAME;

export const FIRE_API = process.env.REACT_APP_FIRE_API;

export const FIRE_API_KEY = process.env.REACT_APP_FIRE_API_KEY;