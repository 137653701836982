import {combineReducers} from "redux";

import changeSideBar from "./ChangeState";
import auth from "./auth";
import quotations from "./quotations";
import quotationReducer from "./quotationReducer";
import selectedQuotation from "./selectedQuotation";
import widgets from "./widgets";
import widgetActions from "./widgetActions";
import applicationManager from "./applicationManager";
import userPermissions from "./userPermissions";
import roleManager from "./roleManager";

export default combineReducers({
    changeSideBar:changeSideBar,
    auth:auth,
    quotations:quotations,
    quotationReducer:quotationReducer,
    selectedQuotation:selectedQuotation,
    widgets:widgets,
    widgetActions:widgetActions,
    applicationManager:applicationManager,
    userPermissions:userPermissions,
    roleManager: roleManager
})
