import axios from 'axios';
import {
    SSO_URL, SSO_API_KEY
} from "../../config";


const GET_APPLICATION_DATA_FAILED = "GET_APPLICATION_DATA_FAILED";
const GET_APPLICATION_DATA_SUCCESS = "GET_APPLICATION_DATA_SUCCESS";
const GET_APPLICATION_COUNT_SUCCESS = "GET_APPLICATION_COUNT_SUCCESS";
const GET_APPLICATION_COUNT_FAILED = "GET_APPLICATION_COUNT_FAILED";
const APPLICATION_REMOVE_SUCCESS = "APPLICATION_REMOVE_SUCCESS";
const APPLICATION_REMOVE_FAILED = "APPLICATION_REMOVE_FAILED";
const APPLICATION_ENABLE_SUCCESS = "APPLICATION_ENABLE_SUCCESS";
const APPLICATION_ENABLE_FAILED = "APPLICATION_ENABLE_FAILED";

const INIT_STATE = {
    loading: false,
    errorMessage: null
};

export default function reducer(state = INIT_STATE,action) {
    const {type, payload} = action;

    switch (type){
        case GET_APPLICATION_DATA_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_APPLICATION_DATA_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_APPLICATION_COUNT_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_APPLICATION_COUNT_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case APPLICATION_REMOVE_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case APPLICATION_REMOVE_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case APPLICATION_ENABLE_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case APPLICATION_ENABLE_FAILED:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }
}

export const applicationData = (limit,offset) => async dispatch => {
    try{
            const res = await axios({
                method: "post",
                url: `${SSO_URL}/api/v1/applications/all`,
                headers: {
                    "Auth-Key": SSO_API_KEY
                },
                data:{
                    limit:limit,
                    offset:offset
                }
            });
            if(res && res['data'].message==='APPLICATIONS_FOUND' && res['data'].status==='OK'){
                return dispatch({type: GET_APPLICATION_DATA_SUCCESS, payload: res['data']});
            }
            else{
                return dispatch({type: GET_APPLICATION_DATA_FAILED, payload: false});
            }
    }catch (e){
        return dispatch({type: GET_APPLICATION_DATA_FAILED, payload: false});
    }
}

export const applicationCount  = () => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/application/count`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='APPLICATION_COUNT' && res['data'].status==='OK'){
            return dispatch({type: GET_APPLICATION_COUNT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_APPLICATION_COUNT_FAILED, payload: 0});
        }
    }catch (e){
        return dispatch({type: GET_APPLICATION_COUNT_FAILED, payload: 0});
    }
}

export const removeApplication = (app_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/application/remove/`+app_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        console.log(res)
        if(res && res['data'].message==='APPLICATION_REMOVED' && res['data'].status==='OK'){
            return dispatch({type: APPLICATION_REMOVE_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: APPLICATION_REMOVE_FAILED, payload: 0});
        }
    }catch (e){
        return dispatch({type: APPLICATION_REMOVE_FAILED, payload: 0});
    }
}

export const enableApplication = (app_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/application/enable/`+app_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        console.log(res)
        if(res && res['data'].message==='APPLICATION_ENABLED' && res['data'].status==='OK'){
            return dispatch({type: APPLICATION_ENABLE_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: APPLICATION_ENABLE_FAILED, payload: 0});
        }
    }catch (e){
        return dispatch({type: APPLICATION_ENABLE_FAILED, payload: 0});
    }
}