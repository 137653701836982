import { diginetAxios } from '../../api/axios';
import { message } from 'antd';
import {error_notification} from "../../utils";

const roleActions = {
    GET_APPLICATIONS_ATTEMPT: 'roles/get_applications_attempt',
    GET_APPLICATIONS_SUCCESS: 'roles/get_applications_success',
    GET_APPLICATIONS_FAILED: 'roles/get_applications_failed',
    GET_WIDGETS_ATTEMPT: 'roles/get_widgets_attempt',
    GET_WIDGETS_SUCCESS: 'roles/get_widgets_success',
    GET_WIDGETS_FAILED: 'roles/get_widgets_failed',
    GET_WIDGET_ACTIONS_ATTEMPT: 'roles/get_widget_actions_attempt',
    GET_WIDGET_ACTIONS_SUCCESS: 'roles/get_widget_actions_success',
    GET_WIDGET_ACTIONS_FAILED: 'roles/get_widget_actions_failed',
    INSERT_NEW_ROLE_ATTEMPT: 'roles/insert_new_role_attempt',
    INSERT_NEW_ROLE_SUCCESS: 'roles/insert_new_role_success',
    INSERT_NEW_ROLE_FAILED: 'roles/insert_new_role_failed',
    GET_ROLE_COUNT_ATTEMPT: 'roles/get_role_count_attempt',
    GET_ROLE_COUNT_SUCCESS: 'roles/get_role_count_success',
    GET_ROLE_COUNT_FAILED: 'roles/get_role_count_failed',
    FETCH_ROLES_ATTEMPT: 'roles/fetch_roles_attempt',
    FETCH_ROLES_SUCCESS: 'roles/fetch_roles_success',
    FETCH_ROLES_FAILED: 'roles/fetch_roles_failed',
    CHANGE_STATUS_ATTEMPT: 'roles/change_status_attempt',
    CHANGE_STATUS_SUCCESS: 'roles/change_status_success',
    CHANGE_STATUS_FAILED: 'roles/change_status_failed',
    UPDATE_ROLE_ATTEMPT: 'roles/update_role_attempt',
    UPDATE_ROLE_SUCCESS: 'roles/update_role_success',
    UPDATE_ROLE_FAILED: 'roles/update_role_failed',
    GET_WIDGET_CONTROLLERS_ATTEMPT: 'roles/get_widget_controllers_attempt',
    GET_WIDGET_CONTROLLERS_SUCCESS: 'roles/get_widget_controllers_success',
    GET_WIDGET_CONTROLLERS_FAILED: 'roles/get_widget_controllers_failed',
};

const initialState = {
    loading: false,
    buttonLoading: true,
    applications: null,
    widgets: null,
    widgetActions: null,
    message: null,
    count: 0,
    roles: null,
    widgetControllersData: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case roleActions.GET_APPLICATIONS_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.GET_APPLICATIONS_SUCCESS: {
            return { ...state, loading: false, applications: action.payload };
        }
        case roleActions.GET_APPLICATIONS_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.GET_WIDGETS_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.GET_WIDGETS_SUCCESS: {
            return { ...state, loading: false, widgets: action.payload };
        }
        case roleActions.GET_WIDGETS_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.GET_WIDGET_ACTIONS_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.GET_WIDGET_ACTIONS_SUCCESS: {
            return { ...state, loading: false, widgetActions: action.payload };
        }
        case roleActions.GET_WIDGET_ACTIONS_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.INSERT_NEW_ROLE_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.INSERT_NEW_ROLE_SUCCESS: {
            return { ...state, loading: false, message: action.payload.message};
        }
        case roleActions.INSERT_NEW_ROLE_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.GET_ROLE_COUNT_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.GET_ROLE_COUNT_SUCCESS: {
            return { ...state, loading: false, count: action.payload.data.count};
        }
        case roleActions.GET_ROLE_COUNT_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.FETCH_ROLES_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.FETCH_ROLES_SUCCESS: {
            return { ...state, loading: false, roles: action.payload.data};
        }
        case roleActions.FETCH_ROLES_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.CHANGE_STATUS_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.CHANGE_STATUS_SUCCESS: {
            return { ...state, loading: false, message: action.payload.message};
        }
        case roleActions.CHANGE_STATUS_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.UPDATE_ROLE_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.UPDATE_ROLE_SUCCESS: {
            return { ...state, loading: false, message: action.payload.message};
        }
        case roleActions.UPDATE_ROLE_FAILED: {
            return { ...state, loading: false };
        }
        case roleActions.GET_WIDGET_CONTROLLERS_ATTEMPT: {
            return { ...state, loading: true };
        }
        case roleActions.GET_WIDGET_CONTROLLERS_SUCCESS: {
            return { ...state, loading: false, widgetControllersData: action.payload };
        }
        case roleActions.GET_WIDGET_CONTROLLERS_FAILED: {
            return { ...state, loading: false };
        }
        default: {
            return state;
        }
    }
}


export const getApplications = ()  => async dispatch => {
    dispatch({ type: roleActions.GET_APPLICATIONS_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/applications/all`);
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.GET_APPLICATIONS_SUCCESS, payload: data.data });
        else {
            error_notification('Getting Applications failed');
            dispatch({ type: roleActions.GET_APPLICATIONS_FAILED })
        };
    } catch (e) {
        dispatch({ type: roleActions.GET_APPLICATIONS_FAILED });
        error_notification('Getting Applications failed');
    }
}

export const getWidgets = (application) => async dispatch => {
    dispatch({ type: roleActions.GET_WIDGETS_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/widgets_by_app_id`, {
            application: application
        });
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.GET_WIDGETS_SUCCESS, payload: data.data });
        else {
            error_notification('Getting Widgets failed');
            dispatch({ type: roleActions.GET_WIDGETS_FAILED })
        };
    } catch (e) {
        dispatch({ type: roleActions.GET_WIDGETS_FAILED });
        error_notification('Getting Widgets failed');
    }
}

export const getWidgetActions = (widgets) => async dispatch => {
    dispatch({ type: roleActions.GET_WIDGET_ACTIONS_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/widgets_actions_by_widgets`,{
            widgets: widgets
        })
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.GET_WIDGET_ACTIONS_SUCCESS, payload: data.data });
        else {
            error_notification('Getting Widgets Actions failed');
            dispatch({ type: roleActions.GET_WIDGET_ACTIONS_FAILED })
        };
    } catch (e) {
        dispatch({ type: roleActions.GET_WIDGET_ACTIONS_FAILED });
        error_notification('Getting Widgets Actions failed');
    }
}

export const insertNewRole = (roleData) => async dispatch => {
    dispatch({ type: roleActions.INSERT_NEW_ROLE_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/insert_role`,{
            roleData: roleData
        });
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.INSERT_NEW_ROLE_SUCCESS, payload: data });
        else {
            error_notification(data.message);
            dispatch({ type: roleActions.INSERT_NEW_ROLE_FAILED })
        };
    } catch (e) {
        dispatch({ type: roleActions.INSERT_NEW_ROLE_FAILED });
        error_notification('Something went wrong! Please try again.');
    }
}

export const getRolesCount = () => async dispatch => {
    dispatch({ type: roleActions.GET_ROLE_COUNT_ATTEMPT });
    try {
        const { data } = await diginetAxios.get(`/api/v1/role_count`);
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.GET_ROLE_COUNT_SUCCESS, payload: data });
    } catch (e) {
        dispatch({ type: roleActions.GET_ROLE_COUNT_FAILED });
        error_notification('Something went wrong! Please try again.');
    }
}

export const fetchRoles = (filters=null,page = 1,pageSize=10,) => async dispatch => {
    dispatch({ type: roleActions.FETCH_ROLES_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/fetch_roles`,{
            filters: filters,
            page: page,
            pageSize: pageSize
        });
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.FETCH_ROLES_SUCCESS, payload: data });
        else{
            error_notification(data.message);
            dispatch({ type: roleActions.FETCH_ROLES_FAILED });
        }
    } catch (e) {
        error_notification('Something went wrong! Please try again.');
        dispatch({ type: roleActions.FETCH_ROLES_FAILED });
    }
}

export const changeStatus = (id, status) => async dispatch => {
    dispatch({ type: roleActions.CHANGE_STATUS_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/change_role_status`,{
            id: id,
            status: status
        });
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.CHANGE_STATUS_SUCCESS, payload: data });
        else{
            error_notification(data.message);
            dispatch({ type: roleActions.CHANGE_STATUS_FAILED });
        }
    } catch (e) {
        error_notification('Something went wrong! Please try again.');
        dispatch({ type: roleActions.CHANGE_STATUS_FAILED });
    }
}

export const updateRole = (roleData) => async dispatch => {
    dispatch({ type: roleActions.UPDATE_ROLE_ATTEMPT });
    try {
        const { data } = await diginetAxios.post(`/api/v1/update_role`,{
            roleData: roleData
        });
        if (data && data.message && data.status==='OK')dispatch({ type: roleActions.UPDATE_ROLE_SUCCESS, payload: data });
        else{
            error_notification(data.message);
            dispatch({ type: roleActions.UPDATE_ROLE_FAILED });
        }
    } catch (e) {
        error_notification('Something went wrong! Please try again.');
        dispatch({ type: roleActions.UPDATE_ROLE_FAILED });
    }
}

export const getWidgetControllers = (widget, widgetAction) => async dispatch => {
    dispatch({ type: roleActions.GET_WIDGET_CONTROLLERS_ATTEMPT });
    try {

        const { data } = await diginetAxios.post('/api/v1/get_widgets_controllers', {
            widget: widget,
            widget_action: widgetAction
        });

        if (data && data.message && data.status==='OK')dispatch({ type:roleActions.GET_WIDGET_CONTROLLERS_SUCCESS, payload: data.data });
        else {
            error_notification(data.message);
            dispatch({ type: roleActions.GET_WIDGET_CONTROLLERS_FAILED });
        }
    } catch (e) {
        error_notification('Something went wrong! Please try again.');
        dispatch({type: roleActions.GET_WIDGET_CONTROLLERS_FAILED});
    }
}

export default reducer;
