import axios from 'axios';
import { diginetAxios } from '../../api/axios';
import {
    SSO_URL, SSO_API_KEY
} from "../../config";
import { message } from 'antd';

const INIT_STATE = {
    loading: false,
    errorMessage: null,
    mfaStatus: null
};

const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
const GET_USER_COUNT_SUCCESS = "GET_USER_COUNT_SUCCESS";
const GET_USER_COUNT_FAILED = "GET_USER_COUNT_FAILED";
const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
const FIND_USER_FAILED = "FIND_USER_FAILED";
const FETCH_USER_PERMISSION_SUCCESS = "FETCH_USER_PERMISSION_SUCCESS";
const FETCH_USER_PERMISSION_FAILED = "FETCH_USER_PERMISSION_FAILED";
const FETCH_USER_PERMISSION_COUNT_SUCCESS = "FETCH_USER_PERMISSION_COUNT_SUCCESS";
const FETCH_USER_PERMISSION_COUNT_FAILED = "FETCH_USER_PERMISSION_COUNT_FAILED";
const PERMISSION_REVOKE_SUCCESS = "PERMISSION_REVOKE_SUCCESS";
const PERMISSION_REVOKE_FAILED = "PERMISSION_REVOKE_FAILED";
const PERMISSION_GRANT_SUCCESS = "PERMISSION_GRANT_SUCCESS";
const PERMISSION_GRANT_FAILED = "PERMISSION_GRANT_FAILED";
const USER_SEARCH_SUCCESS = "USER_SEARCH_SUCCESS";
const USER_SEARCH_FAILED = "USER_SEARCH_FAILED";
const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";
const GET_COMPANY_BRANCHES_SUCCESS = "GET_COMPANY_BRANCHES_SUCCESS";
const GET_COMPANY_BRANCHES_FAILED = "GET_COMPANY_BRANCHES_FAILED";
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
const ADD_USER_FAILED = "ADD_USER_FAILED";
const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
const REMOVE_USER_FAILED = "REMOVE_USER_FAILED";
const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
const ENABLE_USER_FAILED = "ENABLE_USER_FAILED";
const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS"
const GET_ALL_ROLES_FAILED = "GET_ALL_ROLES_FAILED";
const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS"
const ADD_ROLES_FAILED = "ADD_ROLES_FAILED";

const userPermissions = {
    MFA_ENABLE_ATTEMPT: "user_permissions/mfa_enable_attempt",
    MFA_ENABLE_SUCCESS: "user_permissions/mfa_enable_success",
    MFA_ENABLE_FAILED: "user_permissions/mfa_enable_failed",
}


const reducer =  (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type){
        case GET_USER_DATA_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_USER_DATA_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_USER_COUNT_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_USER_COUNT_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case FIND_USER_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case FIND_USER_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case FETCH_USER_PERMISSION_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case FETCH_USER_PERMISSION_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case FETCH_USER_PERMISSION_COUNT_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case FETCH_USER_PERMISSION_COUNT_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case PERMISSION_REVOKE_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case PERMISSION_REVOKE_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case USER_SEARCH_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case USER_SEARCH_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_COMPANIES_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_COMPANIES_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_COMPANY_BRANCHES_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_COMPANY_BRANCHES_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case ADD_USER_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case ADD_USER_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case REMOVE_USER_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case REMOVE_USER_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case ENABLE_USER_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case ENABLE_USER_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_ALL_ROLES_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_ALL_ROLES_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case ADD_ROLES_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case ADD_ROLES_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case userPermissions.MFA_ENABLE_ATTEMPT:
            return {...state, loading: true};
        case userPermissions.MFA_ENABLE_SUCCESS:
            return {...state, mfaStatus: payload};
        case userPermissions.MFA_ENABLE_FAILED:
            return {...state, loading: false};
        default:
            return state;
    }
}

export const getUserData = (limit,offset) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/all`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                limit:limit,
                offset:offset
            }
        });
        if(res && res['data'].message==='USERS_FOUND' && res['data'].status==='OK'){
            return dispatch({type: GET_USER_DATA_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_USER_DATA_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: GET_USER_DATA_FAILED, payload: false});
    }
}

export const countUsers = () => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/count`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='USERS_COUNT' && res['data'].status==='OK'){
            return dispatch({type: GET_USER_COUNT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_USER_COUNT_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: GET_USER_COUNT_FAILED, payload: false});
    }
}

export const findUser = (user_id) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/details`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                user_id:user_id
            }
        });
        if(res && res['data'].message==='USER_FOUND' && res['data'].status==='OK'){
            return dispatch({type: FIND_USER_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: FIND_USER_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: FIND_USER_FAILED, payload: false});
    }
}

export const fetchUserPermissions = (userEmail,limit,offset) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/permissions/all`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                email:userEmail,
                limit:limit,
                offset:offset
            }
        });
        if(res && res['data'].message==='PERMISSIONS_FOUND' && res['data'].status==='OK'){
            return dispatch({type: FETCH_USER_PERMISSION_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: FETCH_USER_PERMISSION_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: FETCH_USER_PERMISSION_FAILED, payload: false});
    }
}

export const fetchUserPermissionsCount = (userEmail) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/permissions/count/`+userEmail,
            headers: {
                "Auth-Key": SSO_API_KEY
            },

        });
        if(res && res['data'].message==='USER_PERMISSIONS_COUNT' && res['data'].status==='OK'){
            return dispatch({type: FETCH_USER_PERMISSION_COUNT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: FETCH_USER_PERMISSION_COUNT_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: FETCH_USER_PERMISSION_COUNT_FAILED, payload: false});
    }
}

export const revokePermission = (userEmail,widget_code,widget_action_code) => async dispatch => {
    try{

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/permission/revoke`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "dataType":"json"
            },
            data:{
                email: userEmail,
                widget_code: widget_code,
                widget_action_code: widget_action_code
            }

        });
        if(res && res['data'].message==='PERMISSION_REVOKED' && res['data'].status==='OK'){
            return dispatch({type: PERMISSION_REVOKE_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: PERMISSION_REVOKE_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: PERMISSION_REVOKE_FAILED, payload: false});
    }
}

export const grantPermission = (userEmail,widget_code,widget_action_code) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/permission/grant`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },data:{
                email: userEmail,
                widget_code: widget_code,
                widget_action_code: widget_action_code
            }

        });
        if(res && res['data'].message==='PERMISSION_GRANTED' && res['data'].status==='OK'){
            return dispatch({type: PERMISSION_GRANT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: PERMISSION_GRANT_FAILED, payload: {error:res['data'].data.message}});
        }
    }catch (e){
        return dispatch({type: PERMISSION_GRANT_FAILED, payload: false});
    }
}

export const searchUser =  (keyword) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/search`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                key_word:keyword
            }
        });
        if(res && res['data'].message==='USER_FOUND' && res['data'].status==='OK'){

            return dispatch({type: USER_SEARCH_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: USER_SEARCH_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: USER_SEARCH_FAILED, payload: false});
    }
}

export const getCompanies = () => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/company/companies`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='FOUND_COMPANIES' && res['data'].status==='OK'){
            return dispatch({type: GET_COMPANIES_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_COMPANIES_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: GET_COMPANIES_FAILED, payload: false});
    }
}

export const getCompanyBranches = () => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/company/branch`,
            headers: {
                "Auth-Key": SSO_API_KEY,
            },
        });
        if(res && res['data'].message==='FOUND_COMPANY_BRANCHES' && res['data'].status==='OK'){
            return dispatch({type: GET_COMPANY_BRANCHES_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_COMPANY_BRANCHES_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: GET_COMPANY_BRANCHES_FAILED, payload: false});
    }
}

export const addNewUser = (data) => async dispatch => {
    try{

        const userData = {
            "company":data['company'],
            "branch":data['branch'].toString(),
            "email":data['email'],
            "first_name":data['first_name'],
            "last_name":data['last_name'],
            "agent_code":data['agent_code'],
            "comp_type":data['comp_type']
        }

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/new`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "Content-Type": "application/json",
                "Accept":"application/json"
            },
            data:JSON.stringify(userData)
        });
        if(res && res['data'] &&  res['data'].message==='USER_CREATED' && res['data'].status==='OK'){
            return dispatch({type: ADD_USER_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: ADD_USER_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: ADD_USER_FAILED, payload: false});
    }
}

export const removeUser = (email) => async dispatch => {
    try{

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/remove`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "Content-Type": "application/json",
                "Accept":"application/json"
            },
            data:{
                email:email
            }
        });
        if(res &&  res['data'].message==='USER_REMOVED' && res['data'].status==='OK'){
            return dispatch({type: REMOVE_USER_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: REMOVE_USER_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: REMOVE_USER_FAILED, payload: false});
    }
}

export const enableUser = (email) => async dispatch => {
    try{

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/users/add`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "Content-Type": "application/json",
                "Accept":"application/json"
            },
            data:{
                email:email
            }
        });
        if(res &&  res['data'].message==='USER_ADDED' && res['data'].status==='OK'){
            return dispatch({type: ENABLE_USER_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: ENABLE_USER_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: ENABLE_USER_FAILED, payload: false});
    }
}

export const handleMfa = (id, status) => async dispatch => {
    dispatch({ type : userPermissions.MFA_ENABLE_ATTEMPT });
    try{
        const { data } = await diginetAxios.post('api/v1/user/mfa',{
            id: id,
            status: status
        });
        data?.status === "OK" ? message.success('User updated successfully'):message.success('User update failed');
        dispatch({type : userPermissions.MFA_ENABLE_SUCCESS, payload : data.status});
    }catch (e) {
        message.success('User update failed');
        return dispatch({type: userPermissions.MFA_ENABLE_FAILED, payload: false});
    }
}

export default reducer;


export const getAllRoles = () => async dispatch => {
    try {

        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/roles`,
            headers: {
                "Auth-Key": SSO_API_KEY
            }
        });

        if (res && res['data'].message === 'ROLES_FOUND' && res['data'].status === 'OK') {
            return dispatch({type: GET_ALL_ROLES_SUCCESS, payload: res['data']});
        } else {
            return dispatch({type: GET_ALL_ROLES_FAILED, payload: {error:res['data'].message}})
        }

    }catch (e) {
        return dispatch({type: GET_ALL_ROLES_FAILED, payload: false});
    }
}

export const addUserRole = (data) => async dispatch => {
    try {

        const res = await axios({
            method: "post",
            "url": `${SSO_URL}/api/v1/add_role`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data: data
        });

        if (res && res['data'].message === 'USER_ROLE_SAVED' && res['data'].status === 'OK') {
            return dispatch({type: ADD_ROLES_SUCCESS, payload: res['data']});
        } else {
            return dispatch({type: ADD_ROLES_FAILED, payload: {error:res['data'].message}});
        }

    } catch (e) {
        return dispatch({type: ADD_ROLES_FAILED, payload: false})
    }
}