import Cookies from 'universal-cookie';
import {notification} from "antd";

export function SetCookies(cookie_name,cookie){
    const cookies = new Cookies();
    cookies.set(cookie_name, cookie, {expires: new Date(Date.now()+18000000),maxAge :18000});
}

export function GetCookies(cookie_name){
    const cookies = new Cookies();
    const cookie_value = cookies.get(cookie_name);
    return cookie_value;
}

export function RemoveCookie(cookie_name){
    const cookies = new Cookies();
    cookies.remove(cookie_name,{path:"/"});
}

export function success_notification(notification_message){
    notification.success({
        placement: 'bottomRight',
        duration:3,
        message:notification_message,
        onClick: () => {
        },
    })
}

export function error_notification(error_message){
    notification.error({
        placement: 'bottomRight',
        duration:3,
        message:error_message,
        onClick: () => {
        },
    })
}

export function redirectToHome(){
    window.location.href = process.env.REACT_APP_SSO_URL;
}

export async function logout() {
    RemoveCookie('digi_session');
    RemoveCookie('digi_widgets');
    RemoveCookie('digi_user');
    window.location.href = process.env.REACT_APP_SSO_URL+"/logout";
}