import axios from 'axios';
import {
    SSO_URL, SSO_API_KEY
} from "../../config";

const GET_WIDGETS_DATA_SUCCESS = "GET_WIDGETS_DATA_SUCCESS";
const GET_WIDGETS_DATA_FAILED = "GET_WIDGETS_DATA_FAILED";
const GET_WIDGETS_COUNT_SUCCESS = "GET_WIDGETS_COUNT_SUCCESS";
const GET_WIDGETS_COUNT_FAILED = "GET_WIDGETS_COUNT_FAILED";
const GET_WIDGETS_SEARCH_SUCCESS = "GET_WIDGETS_SEARCH_SUCCESS";
const GET_WIDGETS_SEARCH_FAILED = "GET_WIDGETS_SEARCH_FAILED";
const REMOVE_WIDGET_SUCCESS = "REMOVE_WIDGET_SUCCESS";
const REMOVE_WIDGET_FAILED = "REMOVE_WIDGET_FAILED";
const ADD_WIDGET_SUCCESS = "ADD_WIDGET_SUCCESS";
const ADD_WIDGET_FAILED = "ADD_WIDGET_FAILED";
const UPDATE_WIDGET_SUCCESS = "UPDATE_WIDGET_SUCCESS";
const UPDATE_WIDGET_FAILED = "UPDATE_WIDGET_FAILED";
const CREATE_WIDGET_SUCCESS = "CREATE_WIDGET_SUCCESS";
const CREATE_WIDGET_FAILED = "CREATE_WIDGET_FAILED";

const INIT_STATE = {
    loading: false,
    errorMessage: null
};

export default function reducer(state = INIT_STATE,action) {
    const {type, payload} = action;

    switch (type){
        case GET_WIDGETS_DATA_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_WIDGETS_DATA_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_WIDGETS_COUNT_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_WIDGETS_COUNT_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_WIDGETS_SEARCH_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case GET_WIDGETS_SEARCH_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case REMOVE_WIDGET_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case REMOVE_WIDGET_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case ADD_WIDGET_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case ADD_WIDGET_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case UPDATE_WIDGET_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case CREATE_WIDGET_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case CREATE_WIDGET_SUCCESS:
            return {...state, payload: payload, errorMessage: null};
        case UPDATE_WIDGET_FAILED:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }
}

export const widgetsData = (limit,offset,appId) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget/all`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data: {
                "limit": limit,
                "offset": offset,
                "appId":appId
            }
        });

        if( res['data'].message==='WIDGETS_FOUND'){
            const payload = [];
            res['data'].data.map((item)=>{
                payload.push(
                    {
                        id:item['id'],
                        name:item['name'],
                        application:item['application'].app_name,
                        fk_app_id:item['application'].id,
                        short_code:item['short_code'],
                        url:item['url'],
                        widget_parent:item['widget_parent'],
                        icon:item['icon'],
                        is_menu:item['is_menu'],
                        display_order:item['display_order'],
                        status:item['status'],
                        app_code:item['app_code'],
                        widget_type:item['widget_type'],
                        parent_widget_code:item['parent_widget_code']
                    }
                );
            })

            return dispatch({type: GET_WIDGETS_DATA_SUCCESS, payload: payload});
        }else{
            return dispatch({type: GET_WIDGETS_DATA_FAILED, payload: {error:res['data'].message}});
        }

    }catch (e){

        return dispatch({type: GET_WIDGETS_DATA_FAILED, payload: false});
    }
}

export const widgetsCount  = (appId) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget/count`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                appId:appId
            }
        });
        if(res && res['data'].message==='WIDGETS_COUNT' && res['data'].status==='OK'){
            return dispatch({type: GET_WIDGETS_COUNT_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: GET_WIDGETS_COUNT_FAILED, payload: 0});
        }
    }catch (e){
        return dispatch({type: GET_WIDGETS_COUNT_FAILED, payload: 0});
    }
}

export const searchWidget = (keyword) => async dispatch => {
    try{
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget/search`,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
            data:{
                key_word:keyword
            }
        });
        if(res && res['data'].message==='WIDGET_FOUND' && res['data'].status==='OK'){
            const payload = [];
            res['data'].data.map((item)=> {
                payload.push(
                    {
                        id: item['id'],
                        name: item['name'],
                        application: item['application'].app_name,
                        fk_app_id: item['application'].id,
                        short_code: item['short_code'],
                        url: item['url'],
                        widget_parent: item['widget_parent'],
                        icon: item['icon'],
                        is_menu: item['is_menu'],
                        display_order: item['display_order'],
                        status: item['status'],
                        app_code: item['app_code'],
                        widget_type: item['widget_type'],
                        parent_widget_code: item['parent_widget_code']
                    }
                );
            });
            return dispatch({type: GET_WIDGETS_SEARCH_SUCCESS, payload: payload});
        }else{
            return dispatch({type: GET_WIDGETS_SEARCH_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: GET_WIDGETS_SEARCH_FAILED, payload: false});
    }
}

export const removeWidget = (short_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/widget/remove/`+short_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='WIDGET_REMOVED' && res['data'].status==='OK'){
            return dispatch({type: REMOVE_WIDGET_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: REMOVE_WIDGET_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: REMOVE_WIDGET_FAILED, payload: false});
    }
}

export const addWidget = (short_code) => async dispatch => {
    try{
        const res = await axios({
            method: "get",
            url: `${SSO_URL}/api/v1/widget/enable/`+short_code,
            headers: {
                "Auth-Key": SSO_API_KEY
            },
        });
        if(res && res['data'].message==='WIDGET_ADDED' && res['data'].status==='OK'){
            return dispatch({type: ADD_WIDGET_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: ADD_WIDGET_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: ADD_WIDGET_FAILED, payload: false});
    }
}

export const createWidget = (data) => async dispatch => {
    try{
        let is_menu,parent;
        if(data['is_menu']){
            is_menu="1";
        }else{
            is_menu="0"
        }

        if(data['widget_parent']){
            parent = data['widget_parent']
        }else{
            parent = ""
        }

        const data_obj = {
            "app_code": data['app_code'].toString(),
            "name": data['name'].toString(),
            "code": data['short_code'].toString(),
            "is_menu_item": is_menu.toString(),
            "url": data['url'].toString(),
            "parent_widget_code":parent.toString(),
            "display_order": data['display_order'].toString(),
            "icon": data['icon'].toString(),
            "widget_type": data['widget_type'].toString()
        }
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget/create`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "Content-Type":"application/json",
                "Accept":"application/json",
                "dataType":"json"
            },
            data:JSON.stringify(data_obj)

        });
        if(res && res['data'].message==='WIDGET_CREATED' && res['data'].status==='OK'){
            return dispatch({type: CREATE_WIDGET_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: CREATE_WIDGET_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: CREATE_WIDGET_FAILED, payload: e});
    }
}

export const updateWidget = (data) => async dispatch => {

    try{
        let is_menu,parent_widget,icon,url;
        if(data['is_menu']){
             is_menu="1";
        }else{
            is_menu="0"
        }
        if(data['url']){
            url = data['url'];
        }else{
            url = "";
        }

        if(data['icon']){
            icon = data['icon'];
        }else{
            icon = "";
        }

        if(data['widget_parent']){
            parent_widget = data['widget_parent']
        }else{
            parent_widget = ""
        }
        const data_object = {
            "app_code": data['app_code'],
            "name": data['name'],
            "code": data['code'],
            "is_menu_item": is_menu,
            "url": url,
            "parent_widget_code":parent_widget,
            "display_order": data['display_order'],
            "icon": icon,
            "widget_type": data['widget_type']
        }

        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widget/update`,
            headers: {
                "Auth-Key": SSO_API_KEY,
                "Content-Type":"application/json",
                "Accept":"application/json",
                "dataType":"json"
            },
            data:JSON.stringify(data_object)

        });

        if(res && res['data'].message==='WIDGET_UPDATED' && res['data'].status==='OK'){
            return dispatch({type: UPDATE_WIDGET_SUCCESS, payload: res['data']});
        }else{
            return dispatch({type: UPDATE_WIDGET_FAILED, payload: {error:res['data'].message}});
        }
    }catch (e){
        return dispatch({type: UPDATE_WIDGET_FAILED, payload: false});
    }
}

