import axios from "axios";
import {FIRE_API,FIRE_API_KEY} from '../../config';
import {GetCookies} from "../../utils";

const INIT_STATE = {
    errorMessage: null
};

const REQUEST_QUOTATIONS_SUCCESS = "REQUEST_QUOTATIONS_SUCCESS";
const REQUEST_QUOTATIONS_FAIL = "REQUEST_QUOTATIONS_FAIL";
const SELECT_QUOTATION = "SELECT_QUOTATION";
const REQUEST_COVER_NOTE_SUCCESS = "REQUEST_COVER_NOTE_SUCCESS";
const REQUEST_COVER_NOTE_FAILED = "REQUEST_COVER_NOTE_FAILED";
const GET_COVERNOTE_SUCCESS = "GET_COVERNOTE_SUCCESS";
const GET_COVERNOTE_FAILED = "GET_COVERNOTE_FAILED";

export default function reducer(state=INIT_STATE,action){
    const {type, payload} = action;

    switch (type) {
        case REQUEST_QUOTATIONS_SUCCESS:
            return {...state, loading: false, payload:payload,errorMessage: null};
        case REQUEST_QUOTATIONS_FAIL:
            return {...state, loading: false, errorMessage: payload};
        case REQUEST_COVER_NOTE_SUCCESS:
            return {...state, loading: false, payload:payload,errorMessage: null};
        case REQUEST_COVER_NOTE_FAILED:
            return {...state, loading: false, errorMessage: payload};
        case GET_COVERNOTE_SUCCESS:
            return {...state, loading: false, payload:payload,errorMessage: null};
        case GET_COVERNOTE_FAILED:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }

}

export const FetchQuotations = (data) => async dispatch => {
    var config = {
        method: 'post',
        url: FIRE_API+ '/v1/quotation',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Api-Key':FIRE_API_KEY
        },
        data: {
            "sumInsured": parseInt(data['sumInsured'].replace(/,/g, '')),
            "purpose": 'Private',
            "insuredName": data['insuredName'],
            "addressNo": data['addressNo'],
            "streetOne": data['streetOne'],
            "streetTwo": data['streetTwo'],
            "streetThree": data['streetThree'],
            "city": data['city'],
            "channel": data['channel']
        }
    };


    return axios(config)
        .then(function (response) {
            let formData = response.data[0].data.params["quotation_details"];
            let requestKey = response.data[0].data.requestKey;
            var config2 = {
                method: 'post',
                url: FIRE_API + '/v1/premiums',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Api-Key':FIRE_API_KEY
                },
                data: {
                    request_key: requestKey,
                }
            };

            let quotationDetails = response.data[0].data;
            if (response.data[0].status === 200) {
                return axios(config2)
                    .then(function (ress) {
                        dispatch({
                            type: REQUEST_QUOTATIONS_SUCCESS,
                            payload: [ress.data, formData, quotationDetails]
                        })
                    })
            } else {
                dispatch({
                    type: REQUEST_QUOTATIONS_FAIL,
                    payload: "error"
                })
            }


        })
        .catch(function (error) {
            return "error";
        });
}

export const SelectQuotation = (post, formData, requestQuotation) => {
    return {
        type: SELECT_QUOTATION,
        payload: [post, formData, requestQuotation],
    }
}

export const RequestCoverNote = (post, selectedQuotation, quotationDetails) => {

    const user = GetCookies('digi_user');
    const requestArray = {
        "requestKey": quotationDetails.requestKey,
        "quotationNumber": selectedQuotation.quotationNumber,
        "company_id": selectedQuotation.company_id,
        "prepared_by": "0",
        "basicPremium": parseFloat(selectedQuotation.breakdown.basicPremium),
        "sumInsured": parseFloat(quotationDetails.params.quotation_details.sumInsured),
        "srcc": parseFloat(selectedQuotation.breakdown.srcc),
        "tc": parseFloat(selectedQuotation.breakdown.tc),
        "cess": parseFloat(selectedQuotation.breakdown.cess),
        "stampFee": parseFloat(selectedQuotation.breakdown.stampFee),
        "vat": parseFloat(selectedQuotation.breakdown.vat),
        "totalPremium": parseFloat(selectedQuotation.breakdown.totalPremium),
        "title": post.title,
        "name_initials": post.name_initials,
        "calling_name": post.calling_name,
        "addressNo": post.address_no,
        "streetOne": post.street_1,
        "streetTwo": post.street_2,
        "city": post.city,
        "nic_no": post.nic_no,
        "email_address": post.email_address,
        "mobile_number": post.mobile_number,
        "owner_title": post.owner_title,
        "pr_owner_name_initials": post.pr_owner_name_initials,
        "pr_owner_nic_no": post.pr_owner_nic_no,
        "pr_owner_address_street1": post.pr_owner_address_street1,
        "pr_owner_address_street2": post.pr_owner_address_street2,
        "pr_owner_address_city": post.pr_owner_address_city,
        "status": 1,
        "pr_fixtures": post.pr_fixtures,
        "conf_bank_name": user.company.code,
        "conf_bank_branch": user.branch.name,
        "conf_bank_user": user.name
    }

    var config = {
        method: 'post',
        url: FIRE_API + '/v1/buy',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Api-Key':FIRE_API_KEY
        },
        data:
        requestArray

    };

    return axios(config)
        .then(function (response) {
            if (response.data.status === 200) {
                return {
                    type: REQUEST_COVER_NOTE_SUCCESS,
                    payload: [response.data.status,requestArray]
                }
            } else {
                return {
                    type: REQUEST_COVER_NOTE_FAILED,
                    payload: [response.data.status,response.data.message]
                }
            }

        })
        .catch(function (error) {
            return "error";
        });
}


export const getQuotations = (requestKey) =>async dispatch => {
    var config = {
        method: 'post',
        url: process.env.REACT_APP_FIRE_API+'/v1/cover',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Api-Key':process.env.REACT_APP_FIRE_API_KEY
        },
        data: {
            quotationNo:requestKey
        }
    };

    return axios(config)
        .then(function (response) {
            return dispatch({type: GET_COVERNOTE_SUCCESS, payload: response});
        })
        .catch(function (error) {
            return dispatch({type: GET_COVERNOTE_FAILED, payload: error});
        });

}
