
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './css/App.css';
const dotenv = require('dotenv')
dotenv.config()

// Containers
const Layout = React.lazy(()=>import('./components/containers/Layout'));
const Auth = React.lazy(()=>import('./pages/Auth'));
const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)

class App extends Component{
  render() {
    return(
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
                <Route exact path="/" name="Auth" component={Auth} />
                <Route  path="/" name="Home" render={props => <Layout {...props}/>} />
            </Switch>
          </React.Suspense>
        </Router>
    )
  }
}

export default App;
