let initialState = 'responsive';

const changeSideBar = (state = initialState, { type, ...rest }) => {
    switch (type){
        case 'set':
            if(state===true){
                return initialState=false
            }else{
                return initialState=true
            }
        default:
            return initialState
    }
};

export default changeSideBar;