import axios from 'axios';

import {
    AUTH_API,
    AUTH_USER_NAME,
    AUTH_PWD,
    API_KEY,
    SSO_URL,
    APP_TYPE,
    APP_NAME
} from "../../config";

const GET_TOKEN = "GET_TOKEN";
const GET_TOKEN_FAILED = "GET_TOKEN_FAILED";
const GET_TOKEN_DONE = "GET_TOKEN_DONE";
const AUTH_VERIFY = "AUTH_VERIFY";
const GET_AUTH_VERIFY_FAILED = "GET_AUTH_VERIFY_FAILED";
const GET_AUTH_VERIFY_SUCCESS = "GET_AUTH_VERIFY_SUCCESS";
const GET_WIDGETS_SUCCESS = "GET_WIDGETS_SUCCESS";
const GET_WIDGETS_FAILED = "GET_WIDGETS_FAILED";


const INIT_STATE = {
    loading: false,
    errorMessage: null
};

export default function reducer(state = INIT_STATE,action) {
    const {type, payload} = action;

    switch (type) {
        case GET_TOKEN:
            return {...state, loading: false, errorMessage: null};
        case GET_TOKEN_DONE:
            return {...state, loading: false};
        case GET_TOKEN_FAILED:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }
}

export const getAuthToken = () => async dispatch =>{
    try{
        dispatch({type: GET_TOKEN});
        const res = await axios({
            method: "post",
            url: `${AUTH_API}token`,
            headers: {
                api_key: API_KEY
            },
            data: {
                user_name: AUTH_USER_NAME,
                password: AUTH_PWD
            }
        });

        const auth_key = res.data.data.auth_key;
        return dispatch({type: GET_TOKEN_DONE, payload: auth_key});
    }catch (e) {
        return dispatch({type: GET_TOKEN_FAILED, payload: false});
    }
}

export const authVerify = (token,key) => async dispatch => {
    try{
        dispatch({type: AUTH_VERIFY});
        const data = JSON.stringify({"token": token, "type": APP_TYPE, "app": APP_NAME});
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/verify`,
            headers: {
                'api_key': key,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            data: data

        });

        const auth_verify = res.data;

        return dispatch({type: GET_AUTH_VERIFY_SUCCESS, payload: auth_verify});
    }catch (e) {
        return dispatch({type: GET_AUTH_VERIFY_FAILED, payload: false});
    }
}

export const getWidgets = (token,key) => async dispatch => {
    try{
        dispatch({type: AUTH_VERIFY});
        const data = JSON.stringify({"token": token, "type": APP_TYPE, "app": APP_NAME});
        const res = await axios({
            method: "post",
            url: `${SSO_URL}/api/v1/widgets`,
            headers: {
                'api_key': key,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            data: data

        });

        const auth_verify = res.data;

        return dispatch({type: GET_WIDGETS_SUCCESS, payload: auth_verify});
    }catch (e) {
        return dispatch({type: GET_WIDGETS_FAILED, payload: false});
    }
}